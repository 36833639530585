import * as React from 'react';
import { AppScreen } from '../../components';
import { AccountSetupForm } from '../../forms/AccountSetupForm';

export interface AccountSetupScreenProps {}

export default (props: AccountSetupScreenProps) => {
    return (
        <AppScreen title="Account Setup">
            <AccountSetupForm />
        </AppScreen>
    );
};
