import * as yup from 'yup';

export default yup.object().shape({
    code: yup.string()
        .required('Please check your inbox to find the security code.'),

    email: yup.string()
        .email('Please enter a valid email address')
        .required('Email is required.'),

    password: yup.string()
        .required('Password is required.'),

    confirmPassword: yup.string()
        .test(
            'password',    
            'Confirm password does not match your password.',
            (value, options) => {
                const password = options.parent.password;
                return !(password && value && password !== value);
            }
        )
});
