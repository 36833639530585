import { Button, ButtonProps } from '@material-ui/core';
import * as React from 'react';
import Icon from '@material-ui/icons/Refresh';

export interface LoadButtonProps extends ButtonProps {
    loading?: boolean;
}

export default ({ loading = false, children, ...props }: LoadButtonProps) => {
    return (
        <Button {...props}>{loading ? <Icon style={{marginRight: 3, fontSize: 17}} /> : null} {children}</Button>
    );
};
