import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import * as React from 'react';
import { Logo } from '../../components';
import styles from './AppScreen.styles';

export interface AppScreenProps extends WithStyles<typeof styles> {
    title: string;
    children: React.ReactNode[] | React.ReactNode;
}

export default withStyles(styles, { name: 'AppStyles' })(
    ({children, classes, title}: AppScreenProps) => {
        return (
            <Grid container justify="center" alignItems="center" direction="column" alignContent="stretch" className={classes.root}>
                <Logo color="black" />
                <Grid container
                    item
                    xs={10} sm={8} md={6} lg={4} xl={3}
                    className={classes.ct}
                    direction="column"
                >
                    <Grid item className={classes.titleCt}>
                        <Typography variant="h3" className={classes.title} gutterBottom={false}>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.childrenCt}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
);
