import { Alert } from '@material-ui/lab';
import * as React from 'react';

export interface Feedback {
    success: boolean;
    message: React.ReactNode;
}

export interface FormErrorProps {
    feedback: Feedback | null;
}

export default ({ feedback }: FormErrorProps) => {
    if (!feedback) {
        return null;
    }

    const { success, message } = feedback;

    return (
        <Alert severity={success ? 'success' : 'error'} style={{textAlign: 'left'}}>
            {message}
        </Alert>
    );
};
