import { FormLabel } from '@material-ui/core';
import * as React from 'react';

export interface FieldLabelProps {
    htmlFor: string;
    label: string;
    field: React.ReactNode;
}

const FieldLabel = ({ label, field, htmlFor }: FieldLabelProps) => {
    return (
        <div>
            <FormLabel htmlFor={htmlFor} style={{display: 'block', marginBottom: 10}}>{label}</FormLabel>
            {field}
        </div>
    );
};

export default FieldLabel;
