import * as React from 'react';
import './style.css';

export interface LogoProps {
    color?: string;
}

const appNameRegex = /\/\/(.*)\.factsquared.com.*/;

const Logo = ({ color = 'black' }: LogoProps) => {
    const match = window.location.href.match(appNameRegex);
    const appName = match !== null && match.length === 2 ? match[1] : '';

    return (
        <div style={{color}}>
            <div className="factsquared-logo-fact">Fact</div>
            <div className="factsquared-logo-squared">Squared</div>
            <div className="factsquared-logo-fact" style={{color: '#0a959a', marginLeft: 10}}>{appName.toUpperCase()}</div>
        </div>
    );
};

export default Logo;
