import { Button, Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { Feedback, FormFeedback, LoadButton } from '../../components';
import api from '../../utils/api';
import validationSchema from './validationSchema';
import { useHistory } from 'react-router-dom';

export interface PasswordResetFormValues {
    code: string;
    password: string;
    confirmPassword: string;
    email: string;
}

export interface PasswordResetFormProps {
    initialValues?: PasswordResetFormValues;
}

const defaultValues: PasswordResetFormValues = {
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
};

export default ({ initialValues = defaultValues }: PasswordResetFormProps) => {
    const [ feedback, setFeedback ] = React.useState<Feedback | null>(null);
    const history = useHistory();

    const handleSubmit = async (values: PasswordResetFormValues, { setSubmitting }: FormikHelpers<PasswordResetFormValues>) => {
        setFeedback(null);

        let success = false;
        try {
            const response = await api.post<boolean>('/reset', values);
            if (response.status === 200) {
                success = true;
            }
        } catch (e) {}

        if (success) {
            setFeedback({
                success,
                message: (
                    <>
                        <div>Your password has been successfully reset. Now you can use your new credentials to login.</div>
                        <Button style={{marginTop: 10}} variant="text" onClick={() => history.replace('/login')}>Go to login page</Button>
                    </>
                )
            });
        } else {
            setFeedback({
                success,
                message: 'We could not complete your request. Please verify that the code is valid.'
            });
        }

        setSubmitting(false);
    };

    return (
        <Formik<PasswordResetFormValues> initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
                <Grid item xs={12}>
                    <Form>
                        <Grid item style={{marginBottom: 20}}>
                            <Field label="Password Reset Code" name="code" component={TextField} fullWidth />
                        </Grid>

                        <Grid item style={{marginBottom: 20}}>
                            <Field label="Email" name="email" component={TextField} fullWidth />
                        </Grid>

                        <Grid item style={{marginBottom: 20}}>
                            <Field label="Password" name="password" component={TextField} type="password" fullWidth />
                        </Grid>

                        <Grid item style={{marginBottom: 20}}>
                            <Field
                                label="Confirm Password"
                                name="confirmPassword"
                                component={TextField}
                                type="password"
                                fullWidth
                            />
                        </Grid>

                        <FormFeedback feedback={feedback} />

                        <Grid container direction="row" style={{marginTop: 30}} alignItems="center" alignContent="center" justify="center">
                            <LoadButton loading={isSubmitting} type="submit" style={{margin: '0 auto'}} variant="contained" color="primary" size="large">
                                Reset Password
                            </LoadButton>
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Formik>
    );
};
