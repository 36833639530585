import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { AppScreen } from '../../components';
import { PasswordRecoveryForm } from '../../forms/PasswordRecoveryForm';

export default () => {
    return (
        <AppScreen title="Passsword Recovery">
            <Grid item xs={12}>
                <PasswordRecoveryForm />

                <Grid item>
                    <Button size="small" variant="text" href={`${process.env.PUBLIC_URL}/login`} style={{marginTop: 10}}>Login</Button>
                </Grid>
            </Grid>
        </AppScreen>
    );
};
