import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
    spacing: 8,

    shape: {
        borderRadius: 7
    },

    palette: {
        primary: {
            light: '#757575',
            main: '#28a745',
        },

        secondary: {
            light: '#03b9bf',
            dark: '#006264',
            main: '#0b9499',
        }
    },

    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Helvetica Neue"',
            'Roboto',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
        ].join(','),

        fontSize: 14,

        h1: {
            fontSize: 42,
            fontWeight: 'bold',
            color: '#343a40'
        },

        h2: {
            fontSize: 28,
            fontWeight: 'bold',
            color: '#343a40'
        },

        subtitle1: { fontSize: 20 },

        subtitle2: { fontSize: 18 },

        body1: { fontSize: 16 },

        body2: { fontSize: 14 }
    },

    props: {
        MuiTextField: {
            variant: 'outlined',
            // margin: 'dense'
        },

        MuiButton: {
            size: "small"
        }
    }
});
