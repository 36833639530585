import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import theme from './theme';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { AccountSetupRoute, LoginRoute, PasswordRecoveryRoute, PasswordResetRoute } from './routes';

const history = createBrowserHistory({
    basename: '',
    forceRefresh: false
});

export const App = () => {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route path="/login" exact component={LoginRoute} />
                <Route path="/setup" exact component={AccountSetupRoute} />
                <Route path="/recovery" exact component={PasswordRecoveryRoute} />
                <Route path="/reset" exact component={PasswordResetRoute} />
            </Switch>
        </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
