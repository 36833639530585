import { Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { FormFeedback, Feedback, LoadButton, FieldLabel } from '../../components';
import api from '../../utils/api';
import validationSchema from './validationSchema';

export interface PasswordRecoveryFormValues {
    email: string;
}

export interface PasswordRecoveryFormProps {
    initialValues?: PasswordRecoveryFormValues;
}

const defaultValues: PasswordRecoveryFormValues = {email: ''};

export default ({ initialValues = defaultValues }: PasswordRecoveryFormProps) => {
    const [ feedback, setFeedback ] = React.useState<Feedback | null>(null);

    const handleSubmit = async (values: PasswordRecoveryFormValues, { setSubmitting }: FormikHelpers<PasswordRecoveryFormValues>) => {
        setFeedback(null);

        let success = false;
        try {
            const response = await api.post<boolean>('/recover', values);
            if (response.status === 200) {
                success = true;
            }
        } catch (e) {}

        setFeedback({
            success,
            message: success ?
                'If we recognize your email address, we will send instructions to reset your password.' :
                'We could not complete your request.'
        });

        setSubmitting(false);
    };

    return (
        <Formik<PasswordRecoveryFormValues> initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
                <Grid item xs={12}>
                    <Form>
                        <Grid item style={{marginBottom: 20}}>
                            <FieldLabel
                                htmlFor="email"
                                label="Email"
                                field={<Field id="email" name="email" component={TextField} fullWidth />}
                            />
                        </Grid>

                        <FormFeedback feedback={feedback} />

                        <Grid container direction="row" style={{marginTop: 30}} alignItems="center" alignContent="center" justify="center">
                            <LoadButton loading={isSubmitting} type="submit" style={{margin: '0 auto'}} variant="contained" color="primary" size="large">
                                Recover Password
                            </LoadButton>
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Formik>
    );
};
