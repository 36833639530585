import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { AppScreen } from '../../components';
import { LoginForm } from '../../forms';

const LoginScreen = (props: {}) => {
    return (
        <AppScreen title="Login">
            <Grid container direction="column">
                <LoginForm />

                <Grid item>
                    <Button size="small" variant="text" href={`${process.env.PUBLIC_URL}/recovery`} style={{marginTop: 10}}>Forgot password?</Button>
                </Grid>
            </Grid>
        </AppScreen>
    );
};

export default React.memo(LoginScreen);
