import { Grid } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import * as React from 'react';
import { FormFeedback, Feedback, LoadButton, FieldLabel } from '../../components';
import api from '../../utils/api';
import validationSchema from './validationSchema';

export interface LoginFormValues {
    email: string;
    password: string;
}

export interface LoginFormProps {
    initialValues?: LoginFormValues;
}

const defaultValues: LoginFormValues = {email: '', password: ''};

export default ({ initialValues = defaultValues }: LoginFormProps) => {
    const [ feedback, setFeedback ] = React.useState<Feedback | null>(null);

    const handleSubmit = async (values: LoginFormValues, { setSubmitting }: FormikHelpers<LoginFormValues>) => {
        setFeedback(null);

        let success = false;
        try {
            const response = await api.post<boolean>('/authenticate', values);
            if (response.status === 200) {
                success = true;
            }
        } catch (e) {}

        if (!success) {
            setFeedback({
                success,
                message: 'Incorrect credentials provided.'
            });
        } else {
            // redirect in case of success authentication
            const search = window.location.search;
            if (search) {
                window.location.href = decodeURIComponent(search);
            } else {
                window.location.href = '/';
            }
        }

        setSubmitting(false);
    };

    return (
        <Formik<LoginFormValues> initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
            {({ isSubmitting }) => (
                <Grid item xs={12}>
                    <Form>
                        <Grid item style={{marginBottom: 20}}>
                            <FieldLabel
                                htmlFor="email"
                                label="Email"
                                field={<Field id="email" name="email" component={TextField} fullWidth />}
                            />
                        </Grid>

                        <Grid item style={{marginBottom: 20}}>
                            <FieldLabel
                                htmlFor="password"
                                label="Password"
                                field={<Field id="password" name="password" component={TextField} type="password" fullWidth />}
                            />
                        </Grid>

                        <FormFeedback feedback={feedback} />

                        <Grid container direction="row" style={{marginTop: 30}} alignItems="center" alignContent="center" justify="center">
                            <LoadButton fullWidth loading={isSubmitting} type="submit" style={{margin: '0 auto'}} variant="contained" color="primary" size="large">
                                Login
                            </LoadButton>
                        </Grid>
                    </Form>
                </Grid>
            )}
        </Formik>
    );
};
