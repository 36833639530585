import * as React from 'react';
import { AppScreen } from '../../components';
import { PasswordResetForm } from '../../forms/PasswordResetForm';

export interface PasswordResetScreenProps {}

export default (props: PasswordResetScreenProps) => {
    return (
        <AppScreen title="Reset password">
            <PasswordResetForm />
        </AppScreen>
    );
};
