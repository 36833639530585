import { createStyles, Theme } from "@material-ui/core";

export default (
    (theme: Theme) => {
        const borderColor = theme.palette.grey[500];

        return createStyles({
            root: {
                marginTop: -50,
                height: '100vh'
            },
    
            ct: {
                borderRadius: theme.shape.borderRadius,
                border: `solid 1px ${borderColor}`,
                marginTop: 20,
            },
    
            title: {    
                fontSize: 25,
                textAlign: 'center'
            },
    
            titleCt: {
                padding: theme.spacing(3, 3, 2),
            },

            childrenCt: {
                padding: theme.spacing(3)
            }
        });
    }
);
